
import { defineComponent, ref, reactive, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect';
import InputNumber from 'primevue/inputnumber';
import { useRoute } from 'vue-router';
import router from '@/router';
import Calendar from 'primevue/calendar';
import useOrder from '@/modules/useOrder';
import { generalStore } from '@/store';
import { DateRange } from '@/models/Interfaces';
import useProduct from '@/modules/useProduct';
import { BusinessOrderProductsFilter } from '@/models/BusinessClients';
import { DocumentTypeForFilter } from '@/models/Enums';
import { IdText, IdTextClient } from '@/models/Interfaces';

export default defineComponent({
    props: {
        clientId: {
            type: Number,
            required: true
        }
    },
    components: {
        InputNumber,
        Multiselect,
        Calendar
    },
    name: 'BusinessClientOrderProductsFilter',
    beforeRouteEnter(to, from, next) {
        to.params.return = 'businessClientView';
        if (from.name == 'businessClientEdit') {
            to.params.return = 'businessClientEdit';
        }
        next();
    },
    async setup(props) {
        const returnName = ref('businessClientView');
        const route = useRoute();
        const calendar = ref();
        const providerProducts = ref<IdText[]>([]);
        const providerCoupons = ref<IdText[]>([]);

        const filter = ref<BusinessOrderProductsFilter>(new BusinessOrderProductsFilter(props.clientId));
        const filterCopy: BusinessOrderProductsFilter = generalStore.getters.getBusinessOrderProductsFilter;
        filter.value = JSON.parse(JSON.stringify(filterCopy));
        if (!filter.value) {
            filter.value = new BusinessOrderProductsFilter(props.clientId);
        }
        const { dateRangeValidate } = useProduct();
        const documentTypes = [];
        for (const [propertyKey, propertyValue] of Object.entries(DocumentTypeForFilter)) {
            if (!Number.isNaN(Number(propertyKey))) {
                continue;
            }
            documentTypes.push({ id: propertyValue, name: propertyKey });
        }

        const { convertDate } = useOrder();
        const dateRange = reactive(new DateRange());
        if (filter.value?.createDateStart) {
            dateRange.fromDate = new Date(filter.value?.createDateStart);
        }
        if (filter.value?.createDateEnd) {
            dateRange.toDate = new Date(filter.value?.createDateEnd);
        }

        function ResetFilter() {
            generalStore.commit('saveBusinessOrderProductsFilter', new BusinessOrderProductsFilter(props.clientId));
            router.push({ name: returnName.value, params: { id: props.clientId, page: 4 } });
        }

        function Close() {
            let oldFilter = generalStore.getters.getBusinessOrderProductsFilter;
            if (!oldFilter) {
                oldFilter = new BusinessOrderProductsFilter(props.clientId);
            }
            oldFilter.pageIndex = 0;
            generalStore.commit('saveBusinessOrderProductsFilter', oldFilter);
            router.push({ name: returnName.value, params: { id: props.clientId, page: 4 } });
        }

        function Search() {
            if (filter.value) {
                if (dateRange.fromDate) {
                    filter.value.createDateStart = convertDate(dateRange.fromDate);
                }
                if (dateRange.toDate) {
                    filter.value.createDateEnd = convertDate(dateRange.toDate);
                }

                filter.value.keyword = '';
                filter.value.pageIndex = 0;
            }

            generalStore.commit('saveBusinessOrderProductsFilter', filter.value);
            router.push({ name: returnName.value, params: { id: props.clientId, page: 4 } });
        }

        const dateChanged = () => {
            if (!dateRange.fromDate || !dateRange.toDate) {
                return;
            }
            dateRangeValidate(dateRange);
        };
        async function onMountedAction() {
            providerProducts.value = await generalStore.dispatch('getMyProducts');
            const providerCouponsResp = await generalStore.dispatch('getProviderCoupons');
            providerCoupons.value = providerCouponsResp.filter((x: IdTextClient) => x.clientId == props.clientId);
            returnName.value = route.params.return.toString();
        }
        onMounted(onMountedAction);

        return {
            filter,
            dateRange,
            ResetFilter,
            Close,
            Search,
            calendar,
            dateChanged,
            documentTypes,
            providerProducts,
            providerCoupons
        };
    }
});
